import { Link } from "gatsby"
import React from "react"
import { Container } from "react-bootstrap"
import { PageLinks } from "../../common/site/page-static-links"
import "./GuideCard.scss"

const {
  ImageModule,
} = require("@starberry/gatsby-theme-utils/dist/Modules/ImageModule")

const GuideCard = ({ guideData }) => {
  const imagename = "guide.tile_image.guide_image"
  return (
    <div className="guide-card-wrapper">
      <Container>
        <div className="guide-card-sections">
          {guideData?.map((data) => {
            let processedImages = ""
            processedImages =
              data?.node?.imagetransforms?.tile_image_Transforms ||
              JSON.stringify({})
            return (
              <div className="guide-card-module" key={data.node?.strapi_id}>
                <div className="image-section">
                  <Link to={`/${PageLinks.guide}/${data?.node?.slug}/`}>
                    <ImageModule
                      ImageSrc={data.node?.tile_image}
                      altText={`${data.node?.title}`}
                      imagetransforms={processedImages}
                      renderer="srcSet"
                      imagename={imagename}
                      strapi_id={data?.node?.strapi_id}
                      classNames="img-card"
                    />
                    {/* <img src={data.node?.tile_image.url} alt="pop" /> */}
                  </Link>
                </div>
                <div className="guide-info">
                  <h4>
                    <Link to={`/${PageLinks.guide}/${data?.node?.slug}/`}>
                      {data?.node?.title}
                    </Link>
                  </h4>
                  <p>{data?.node?.area_name}</p>
                </div>
              </div>
            )
          })}
        </div>
      </Container>
    </div>
  )
}

export default GuideCard

import loadable from "@loadable/component"
import { graphql } from "gatsby"
import React from "react"
import GuidesTab from "../components/GuidesTab/GuidesTab"
import ImageCard from "../components/ImageCard/ImageCard"
import Layout from "../components/layout"
import SEO from "../components/seo"

const ListingIntroModule = loadable(() =>
  import("../components/ListingIntroModule/ListingIntroModule")
)

const BreadcrumbModule = loadable(() =>
  import("../components/BreadcrumbModule/BreadcrumbModule")
)

const GuideLandingTemplate = ({ data }) => {
  const PageData = data?.strapiPage
  const guidesData = data?.allStrapiGuide.edges
  const guideCategory = data?.allStrapiGuideCategory.edges
  return (
    <Layout>
      <div className="layout-padding-top">
        <BreadcrumbModule
          subparentlabel={PageData.choose_menu[0]?.strapi_parent?.title}
          subparentlink={PageData.choose_menu[0]?.strapi_parent?.slug}
          title={PageData.title}
          parentlabel={
            PageData.choose_menu[0]?.strapi_parent?.strapi_parent?.title
          }
          parentlink={
            PageData.choose_menu[0]?.strapi_parent?.strapi_parent?.slug
          }
        />

        <div className="white-bg">
          {PageData?.add_page_modules?.length > 0 &&
            PageData.add_page_modules?.map((module, i) => {
              return (
                <div key={module?.id}>
                  {module?.__typename ===
                    "STRAPI__COMPONENT_PAGE_MODULES_PLAIN_CONTENT" && (
                    <ListingIntroModule
                      content={module.content}
                      headingTitle={
                        module.title ? module.title : PageData.title
                      }
                    />
                  )}
                  {i === 0 && (
                    <GuidesTab
                      data={guidesData}
                      guideCategoryList={guideCategory}
                    />
                  )}
                  {module?.strapi_component ===
                    "page-modules.image-with-page" && (
                    <ImageCard
                      {...module}
                      slide_count="4"
                      id={PageData.strapi_id}
                      imagetransforms={PageData.imagetransforms}
                    />
                  )}
                </div>
              )
            })}
        </div>
      </div>
    </Layout>
  )
}

export const Head = ({ data }) => {
  const PageData = data?.strapiPage
  return (
    <SEO
      title={
        PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title
      }
      description={
        PageData?.seo?.metaDescription
          ? PageData?.seo?.metaDescription
          : PageData?.title
      }
    />
  )
}

export default GuideLandingTemplate

export const query = graphql`
  query ($page_id: String) {
    strapiPage(id: { eq: $page_id }) {
      ...PageFragment
      add_page_modules {
        ... on STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE {
          ...GlobalModuleFragment
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_IMAGE_WITH_PAGE {
          id
          secondary_title
          strapi_component
          primary_title
          bg_color
          choose_spacing
          card_items {
            image {
              url
              alternativeText
            }
            custom_link
            cta_label
            cta_link {
              slug
              strapi_parent {
                slug
              }
            }
          }
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_PLAIN_CONTENT {
          __typename
          ...PlainContentFragment
        }
      }
    }

    allStrapiGuideCategory {
      edges {
        node {
          category_name
          slug
          strapi_id
        }
      }
    }

    allStrapiGuide {
      edges {
        node {
          ...GuideFragment
        }
      }
    }
  }
`

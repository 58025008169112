import { navigate } from "gatsby"
import _, { capitalize } from "lodash"
import React, { useEffect, useState } from "react"
import { Container } from "react-bootstrap"
import Select from "react-select"
import usePagination from "../../hooks/usePagination"
import GuideCard from "../GuideCard/GuideCard"
import InnerPagination from "../InnerPagination/InnerPagination"
import "./assets/styles/_index.scss"

const GuidesTab = ({ data, guideCategoryList }) => {
  let querySelectedOption
  if (typeof window !== "undefined") {
    const result = new URLSearchParams(window.location?.search)
    const guide = result?.get("guides")
    querySelectedOption = {
      label: capitalize(guide?.replace(/-/g, " ")),
      value: guide,
    }
  }

  let queryData = ""

  if (
    querySelectedOption?.value !== null &&
    querySelectedOption?.value !== "all-guides"
  ) {
    queryData = _.filter(data, function (o) {
      if (o.node.categories?.length > 0) {
        return o.node.categories?.some(
          (item) => item.slug === querySelectedOption?.value
        )
      }
    })
  } else {
    queryData = data
  }

  const guidesFilters = [{ value: "all-guides", label: "All Guides" }]
  // console.log(querySelectedOption)
  const [filter, setFilter] = useState(
    querySelectedOption?.value ? querySelectedOption?.value : "all-guides"
  )
  // console.log(filter)
  guideCategoryList?.forEach((element) => {
    if (element.node.category_name) {
      guidesFilters.push({
        label: element.node.category_name,
        value: element.node.slug,
      })
    }
  })

  const selectOptions = [
    { value: guidesFilters[0].value, label: guidesFilters[0].label },
  ]

  const [set, setClass] = useState(false)
  const [projects, setProjects] = useState(data)
  const [filteredList, setFilteredList] = useState(
    querySelectedOption?.value !== "all-guides" ? queryData : data
  )

  const [filterOptions, setFilterOptions] = useState(selectOptions)

  // for pagination
  const itemsPerPage = 12
  const { currentItems, currentPage, setCurrentPage } = usePagination({
    items: filteredList,
    itemsPerPage,
  })
  // for pagination

  const newClass = (event, querySelectedOption) => {
    // const getAllCategoryId = document.getElementById("all-cat")
    // if (querySelectedOption?.value) {
    //   if (querySelectedOption?.value === "all-guides") {
    //     getAllCategoryId?.classList.add("active")
    //   } else {
    //     const getElement = document.getElementsByClassName(
    //       querySelectedOption?.value
    //     )
    //     const el = getElement[0]
    //     el && el.classList?.add("active")
    //     getAllCategoryId?.classList.remove("active")
    //   }
    // } else {
    const parent = document.getElementById("filter-link")
    parent.classList.add("filter-active")
    setClass(false)
    const allChildElements = parent.querySelectorAll(".nav-link")
    for (const box of allChildElements) {
      box.classList.remove("active")
    }
    event.target.classList.add("active")
    // }
  }
  // useEffect(() => {
  //   newClass("", querySelectedOption)
  // }, [])
  const applyCategory = (filt) => {
    navigate(`?guides=${filt}`)
    if (filt !== "all-guides") {
      const filtered = _.filter(data, function (o) {
        if (o.node.categories?.length > 0) {
          return o.node.categories?.some((item) => item.slug === filt)
        }
      })

      setProjects(filtered)
      setFilteredList(filtered)
    } else {
      setProjects(data)
      setFilteredList(data)
    }
    setCurrentPage(1)
  }
  useEffect(() => {
    if (
      querySelectedOption?.value !== "all-guides" &&
      querySelectedOption?.value !== null
    ) {
      const filtered = _.filter(data, function (o) {
        if (o.node.categories?.length > 0) {
          return o.node.categories?.some(
            (item) => item.slug === querySelectedOption?.value
          )
        }
      })
      setFilteredList(filtered)
      setFilter(querySelectedOption?.value)
    } else {
      setFilteredList(data)
      setFilter("all-guides")
    }
  }, [querySelectedOption])
  useEffect(() => {
    // debugger
    guidesFilters.slice(1, 100).map((item, i) => {
      _.filter(data, function (o) {
        if (o.node.categories?.length > 0) {
          o.node.categories?.some((blog) => {
            if (blog.slug === item.value) {
              selectOptions.push({ value: item.value, label: item.label })
            }
          })
        }
      })
    })

    setFilterOptions(_.uniqBy(selectOptions, (e) => e.value))
  }, [filteredList])

  return (
    <div className="guides-page-wrapper">
      <Container>
        <div className="guides-top-sections">
          <div className="categories-container">
            <div className="categories-list">
              <Select
                options={filterOptions}
                isSearchable={false}
                onChange={(e) => {
                  setFilter(e.value)
                  applyCategory(e.value)
                }}
                placeholder="All Guides"
                className="select-control"
                classNamePrefix="react-select"
                // styles={customStylesNews}
                value = {
                  filterOptions.filter(option => 
                     option.value === filter)
               }
                components={{
                  DropdownIndicator: () => (
                    <i className="icon down-arrow-light" />
                  ),
                  IndicatorSeparator: () => null,
                }}
              />
            </div>
            <ul className="inner-tab nav-tabs" id="filter-link">
              <li className="nav-item">
                <button
                  type="button"
                  id="all-cat"
                  className={`nav-link tab_fill ${
                    filter === "all-guides" ? "active" : ""
                  }`}
                  onClick={(event) => {
                    setFilter("all-guides")
                    applyCategory("all-guides")
                    newClass(event)
                  }}
                >
                  All Guides
                </button>
              </li>
              {filterOptions?.slice(1, 20).map((option) => (
                <li className="nav-item" key={option.value}>
                  <button
                    type="button"
                    className={`nav-link ${
                      filter === option.value ? "active" : ""
                    }`}
                    onClick={(event) => {
                      setFilter(option.value)
                      applyCategory(option.value)
                      newClass(event)
                    }}
                  >
                    {option.label}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Container>

      <div className="guide_card-listing-wrapper">
        {currentItems?.length > 0 && (
          <GuideCard guideData={currentItems.slice(0, currentItems?.length)} />
        )}

        <Container>
          <InnerPagination
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            totalItems={filteredList.length}
            setCurrentPage={setCurrentPage}
          />
        </Container>
      </div>
    </div>
  )
}

export default GuidesTab
